html, body {
  margin: 0;
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* Ensure MUI components use Lexend */
.MuiTypography-root,
.MuiButton-root,
.MuiInputBase-root,
.MuiMenuItem-root,
.MuiListItem-root,
.MuiTab-root,
.MuiChip-root,
.MuiAlert-root,
.MuiTooltip-root,
.MuiAccordion-root,
.MuiDialog-root,
.MuiSnackbar-root {
  font-family: "Lexend", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

.leafletGreen {
  filter: invert(72%) sepia(99%) saturate(439%) hue-rotate(35deg) brightness(96%) contrast(113%);
}
.leafletRed {
  filter: invert(10%) sepia(95%) saturate(5497%) hue-rotate(336deg) brightness(85%) contrast(104%);
}
.leafletYellow {
  filter: invert(69%) sepia(90%) saturate(851%) hue-rotate(358deg) brightness(102%) contrast(103%)
}

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}